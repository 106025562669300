<template>
	<v-sheet>
		<h4>{{project.internal_name}}</h4>
		<storage-list :folder="folder" :up-to-date="upToDate" @updated="upToDate = true"></storage-list>
		<secondary-navigation show-back>
			<template v-slot:main>
				<nav-item
					@click="dialog = true"
					text="Upload"
					icon="mdi-upload"
				></nav-item>
			</template>
			<template v-slot:append>
				<edit-project-notes v-model="id"></edit-project-notes>
			</template>
		</secondary-navigation>
		<mw-dialog v-model="dialog" @submit="submit" submit-btn cancel-btn>
			<template v-slot:dialog-body>
				<v-select v-model="path" :items="[{text: 'Admin Only', value: ''},{text: 'Client Recordings', value: 'clients/recordings'},{ text: 'Client Documents', value: 'clients/documents' } ]">
				</v-select>
			<mw-upload :location="uploadTo" :allow-select="false"></mw-upload>
			</template>
		</mw-dialog>
	</v-sheet>
</template>

<script>
import NavItem from "@/components/navigation/Item";
import StorageList from "@c/storage/StorageList.vue";
import EditProjectNotes from '@c/edits/EditProjectNotes';
import SecondaryNavigation from "@c/navigation/Secondary.vue";
import MwDialog from "@c/ui/MwDialog.vue";
import MwUpload from "@c/storage/MwUpload.vue";
export default {
	name: "Documents",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			upToDate: false,
			path: "",
			dialog: false,
		};
	},
	computed: {
		uploadTo() {
			return this.folder + this.path;
		},
		folder() {
			return `projects/${this.id}/`;
		},
		project() {
			return this.$store.state.projects.data[this.id];
		},
	},
	methods: {
		submit(){
			this.upToDate = false;
			this.dialog = false;
		}
	},
	components: {
		StorageList,
		SecondaryNavigation,
		NavItem,
		MwUpload,
		MwDialog,
		EditProjectNotes
	},
};
</script>
